/* eslint-disable */
/* Warning: this file is autogerated, any changes would be lost */
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import WpAcfIconTextBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfAllProjectsBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfPageHeaderActionBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'



const Blocks = ({ blocks = [], previewUUID }) => {
  return (
    <>
      {useMemo(() => {
        
        return blocks
      }, [blocks, previewUUID]).map((block, i) => {
        const children = block.innerBlocks ? <Blocks blocks={block.innerBlocks} previewUUID={previewUUID} /> : null;

        if (block.__typename === 'WpAcfIconTextBlock') {
            return <WpAcfIconTextBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfAllProjectsBlock') {
            return <WpAcfAllProjectsBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfPageHeaderActionBlock') {
            return <WpAcfPageHeaderActionBlock {...block} children={children} key={i} />
          }

        return null;
      })}
    </>
  );
};

export default ({ previewUUID }) => {

  const { wpPage: { blocks } } = useStaticQuery(graphql`
    fragment Blocks27 on WpBlock { __typename ...ThemeWordpressGutenbergUnknownBlock  }
    query {
      wpPage(id: {eq: "27"}) {
        blocks {
          ...Blocks27
        }
      }
    }`)

  return <Blocks blocks={blocks} previewUUID={previewUUID} />
}
